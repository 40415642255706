import firebase from 'firebase/app';
import 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyDfPR7cjceF62h4J6fUZL2QoMWb4XBnKVQ",
    authDomain: "coril-identity-e7996.firebaseapp.com",
    databaseURL: "https://coril-identity-e7996.firebaseio.com",
    projectId: "coril-identity-e7996",
    storageBucket: "coril-identity-e7996.appspot.com",
    messagingSenderId: "450521576249",
    appId: "1:450521576249:web:97bdc103fae66e0cdb6ee8",
    measurementId: "G-JCX6XNEVNL"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();

export{
    db,
    firebase
};