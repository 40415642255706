import React, { useState } from 'react';
import Swal from 'sweetalert2';
import Loading from './Loading';
import { db } from '../firebase/firebase-config';
import LOGO from '../images/logos/logo-identity.png';
import FONDO from '../images/fondo/fondo.png';
import { Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';

const Login = ({ history }) => {

    const [isFetching, setFetching] = useState(false);
    const [datos, setDatos] = useState({
        document : '',
        password  : ''
    });

    const handleInputChange = (e) => {
        setDatos({
            ...datos,
            [e.target.name] : e.target.value
        })
    }

    const styleBackground = {
        backgroundImage:  `url(${FONDO})`,
        backgroundSize: 'cover'
    }

    const loginUser = () => {
        const document = datos.document;
        const password = datos.password;
        const VALUEAPIKEY = 'ef2018c59ac4bb739be8698134a89ff2c1ad7608';
        let arrayInstitutions = [];
        if(document === '' || document === undefined){
            Swal.fire('','El n\u00FAmero de documento no se puede encontrar vac\u00EDo','warning');
            return;
        }
        if(password === '' || password === undefined){
            Swal.fire('','No puede dejar vac\u00CDo el campo de contrase\u00F1a','warning');
            return;
        }
        setFetching(true);
        axios({
            method: 'POST',
            url: 'https://5cl.dec.com.pe/api/v1/auth/login',
            data: {
                user_rut: document,
                user_pin: password
            },
            headers: {
                'Content-Type': 'application/json',
                'X-API-KEY' : VALUEAPIKEY
            }
        })
        .then(function (response) {
            const instituciones = response.data.result.institutions;
            const user_rut = (response.data.result.user_rut.length < 8 ) ? "0"+response.data.result.user_rut : response.data.result.user_rut;
            localStorage.setItem('USER_NAME', response.data.result.user_name);
            localStorage.setItem('USER_RUT', user_rut);
            localStorage.setItem('SESSION_ID', response.data.session_id);
            localStorage.setItem('USER_PIN', password);
            db.collection("usuarios").where("user_rut", "==", user_rut).limit(1).get()
            .then(function(userSession) {
                userSession.forEach(function(snapSession) {
                    const PRIMERA_SESSION = snapSession.data().primera_session;
                    localStorage.setItem('TIPO_FIRMA', snapSession.data().tipo_firma);
                    localStorage.setItem('USER_PHONE', snapSession.data().user_phone);
                    console.log(PRIMERA_SESSION);
                    db.collection("empresas").where("institution", "in", instituciones).get()
                    .then(function(institution) {
                        institution.forEach(function(snapInstitutions) {
                            arrayInstitutions.push(snapInstitutions.data());
                        })
                        setFetching(false);
                        if(PRIMERA_SESSION === true){
                            history.push({
                                pathname: '/activar-cuenta',
                                state: {
                                    user_data: response.data.result
                                }
                            });
                        }else{
                            history.push({
                                pathname: '/empresas',
                                state: {
                                    institutions: arrayInstitutions
                                }
                            });
                        }
                        localStorage.setItem('INSTITUTIONS', JSON.stringify(arrayInstitutions));
                    })
                })
            })
        })
        .catch((error) => {
            setFetching(false);
            Swal.fire('','DNI o clave inv\u00E1lida','error');
        });
    }
    
    const verificarDatos = (e) => {
        if(e.keyCode === 13){
            e.preventDefault();
            loginUser();
        }
    }

  return(
      <div className="c-login" style={styleBackground}>
          { isFetching ? <Loading></Loading> : '' }
          <div className="c-login__card">
              <div className="c-card__title">
                <img src={LOGO} alt="Logo Identity" />
                <p>Por favor, <strong>Identificate</strong></p>
              </div>
              <div className="c-card__body">
                  <div className="c-select">
                      <Form.Control as="select" disabled>
                          <option value='00'>DNI</option>
                          <option value='01'>Pasaporte</option>
                          <option value='04'>C.E</option>
                      </Form.Control>
                  </div>
                  <div className="c-input">
                      <Form.Control type="text" placeholder="Numero de documento" name="document" onChange={handleInputChange} onKeyUp={verificarDatos} />
                  </div>
                  <div className="c-input">
                      <Form.Control type="password" placeholder="Contraseña" name="password" onChange={handleInputChange} onKeyUp={verificarDatos} />
                  </div>
              </div>
              <div className="c-card__actions">
                  <button className="c-button c-button--raised c-button--width" onClick={() => loginUser()}>INGRESAR</button>
                  <Link to="generar-contrasena" className="c-forget--password">¿Olvid&oacute; su contraseña?</Link>
              </div>
              <div className="c-card__footer">
                  <p>Todos los derechos reservados por GRUPO CORIL.</p>
              </div>
          </div>
      </div>
  )
}

export default Login;
