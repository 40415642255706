import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import Loading from './Loading';
import PDFViewer from 'pdf-viewer-reactjs'
import { PDFObject } from 'react-pdfobject';
import Iframe from 'react-iframe';
import LOGO_IDENTITY_HORIZONTAL from '../images/logos/logo-identity-horizontal.png';
import { Layout, Drawer, Header, Navigation, Content} from 'react-mdl';
import { Button, Modal, Form} from 'react-bootstrap';
import { NavLink, Link } from 'react-router-dom';
import axios from 'axios';

const DetailDocument = ({history}) => {

    const [isFetching, setFetching] = useState(false);
    const [typeState, setTypeState] = useState("");
    const [detailDocument, setDetailDocument] = useState({});
    const [signerDocument, setSignerDocument] = useState([]);
    const [modalShowDoc, setModalShowDoc] = useState(false);
    const [modalShowObs, setModalShowObs] = useState(false);
    const [modalShowSign, setModalShowSign] = useState(false);

    const SESSION_ID = localStorage.getItem('SESSION_ID');
    const TIPO_FIRMA = localStorage.getItem('TIPO_FIRMA');
    const VALUEAPIKEY = localStorage.getItem('VALUEAPIKEY_COMPANY');
    const USERNAME = localStorage.getItem('USER_NAME');
    const USER_RUT = localStorage.getItem('USER_RUT');
    const USER_PHONE = localStorage.getItem('USER_PHONE');
    const USER_PIN = localStorage.getItem('USER_PIN');
    const INSTITUTION = localStorage.getItem('INSTITUTION');
    const INSTITUTION_IMG = localStorage.getItem('INSTITUTION_IMG');
    const INSTITUTIONS = JSON.parse(localStorage.getItem('INSTITUTIONS'));

    useEffect(()=> {
        console.log(history);
        setDetailDocument(history.location.state.detailDocument.result);
        setSignerDocument(history.location.state.detailDocument.result.signers);
        setTypeState(history.location.state.typeState)
    }, [])

    const logoutUser = () => {
        axios({
            method: 'POST',
            url: 'https://5cl.dec.com.pe/api/v1/auth/logout',
            data: {
                session_id: SESSION_ID
            },
            headers: {
                'Content-Type': 'application/json',
                'X-API-KEY' : VALUEAPIKEY
            }
        })
        .then(function (response) {
            history.push('/');
        })
        .catch((error) => {

        });
    }

    const MyModalDocument = (props) => {
    
        return (
            <Modal show={props.show} onHide={props.onHide} backdrop="static" keyboard={false} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Visualizando documento
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* <iframe sandbox="allow-same-origin allow-scripts allow-forms" src={props.srcIframe} width="100%" height="480" /> */}
                    <PDFViewer
                        document={{
                            url: props.srcIframe,
                        }}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button className="c-button c-button--raised" onClick={props.onHide}>Cerrar</Button>
                </Modal.Footer>
            </Modal>
        );
    }
    
    const MyModalSign = (props) => {
    
        const [isFetching, setFetching] = useState(false);
        const [isPinId, setPinId] = useState("");
        const [datos, setDatos] = useState({
            password : '',
            sms  : ''
        });

        let type_tipo_firma = "";
    
        const handleInputChange = (e) => {
            setDatos({
                ...datos,
                [e.target.name] : e.target.value
            })
        }

        signerDocument.filter(function(signers){
            const signers_rut = (signers.rut.length < 8 ) ? "0"+signers.rut : signers.rut;
            if(signers_rut == USER_RUT){
                type_tipo_firma =  signers.type
            }
        });
    
        const generatePin = () => {
            setFetching(true);
            axios({
                method: 'POST',
                url: 'https://9npyr.api.infobip.com/2fa/2/pin?ncNeeded=true',
                data: {
                    applicationId: "342552A181526F34C8F61F36DF4920B9",
                    messageId: "E9235174395D240B68DF603E1C7745AC",
                    from : "Coril_Identity",
                    to: "+"+USER_PHONE,
                    "placeholders":{}
                },
                headers: {
                    'Authorization': 'App 8c92d1ac9b27c3f3cb0089d81e5bea3d-24643b6d-6f84-4971-afdd-ea8f9de62772',
                    'Content-Type':  'application/json',
                    'Access-Control-Allow-Credentials' : 'true',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET, POST, PATCH, DELETE, PUT, OPTIONS',
                    'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With'
                }
            })
            .then(function (response) {
                setFetching(false);
                setPinId(response.data.pinId);
            })
            .catch((error) => {
                setFetching(false);
            });
        }
    
        const signDocument = (text) => {
            const password = datos.password;
            let role_user = "";
            if(password == '' || password == undefined){
                Swal.fire('','No puede dejar vac\u00CDo el campo de contrase\u00F1a','warning');
                return;
            }
            if(password != USER_PIN){
                Swal.fire('','Contrase\u00F1a inv\u00E1lida','error');
                return;
            }
            props.dataDocument.signers.filter(function(signers){
                const signers_rut = (signers.rut.length < 8 ) ? "0"+signers.rut : signers.rut;
                if(signers_rut == USER_RUT){
                    role_user = signers.role;
                }
            })
            setFetching(true);
            axios({
                method: 'POST',
                url: 'https://5cl.dec.com.pe/api/v1/sign',
                data: {
                    user_rut: USER_RUT,
                    user_pin: password,
                    user_role: role_user,
                    user_institution: INSTITUTION,
                    code: props.dataDocument.code,
                    session_id: SESSION_ID
                },
                headers: {
                    'Content-Type': 'application/json',
                    'X-API-KEY' : VALUEAPIKEY
                }
            })
            .then(function (response) {
                setFetching(false);
                setModalShowSign(false);
                Swal.fire('','Documento '+text+' con \u00E9xito','success');
                history.push('firmas');
            })
            .catch((error) => {
                setFetching(false);
                if (401 === error.response.status) {
                    Swal.fire({
                        icon: 'warning',
                        title: 'Sesi\u00F3n expirada',
                        text: 'Su sesi\u00F3n ha caducado. Ser\u00E1 redirigido a la p\u00E1gina de inicio de sesi\u00F3n para logearse nuevamente.',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        confirmButtonText: `OK`,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            logoutUser()
                        }
                    })
                }
            });
        }
    
        const verifySMS = () => {
            const password = datos.password;
            const sms = datos.sms;
            if(password == '' || password == undefined){
                Swal.fire('','No puede dejar vac\u00CDo el campo de contrase\u00F1a','warning');
                return;
            }
            if(password != USER_PIN){
                Swal.fire('','Contrase\u00F1a inv\u00E1lida','error');
                return;
            }
            if(sms == '' || sms == undefined){
                Swal.fire('','No puede dejar vac\u00CDo el campo de SMS','warning');
                return;
            }
            setFetching(true);
            axios({
                method: 'POST',
                url: 'https://9npyr.api.infobip.com/2fa/2/pin/'+isPinId+'/verify',
                data: {
                    pin: sms
                },
                headers: {
                    'Authorization': 'App 8c92d1ac9b27c3f3cb0089d81e5bea3d-24643b6d-6f84-4971-afdd-ea8f9de62772',
                    'Content-Type':  'application/json',
                    'Access-Control-Allow-Credentials' : 'true',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET, POST, PATCH, DELETE, PUT, OPTIONS',
                    'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With'
                }
            })
            .then(function (response) {
                setFetching(false);
                if(response.data.verified == true){
                    signDocument("firmado");
                }else{
                    setModalShowSign(false);
                    Swal.fire('','SMS inv\u00E1lido','error');
                }
            })
            .catch((error) => {
                setFetching(false);
            });
        }
    
        return (
            <Modal show={props.show} onHide={props.onHide} backdrop="static" keyboard={false} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                { isFetching ? <Loading></Loading> : '' }
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {
                            (type_tipo_firma  == 0) ? 'Firmar documento' : 'Visar documento'
                        }
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="c-input">
                        <Form.Control type="password" placeholder="Contraseña" name="password" onChange={handleInputChange}/>
                    </div>
                    {
                        (type_tipo_firma  == 0)
                        ?
                            (TIPO_FIRMA == 1) ? '' : 
                            <div className="c-input">
                                <label className="c-label">Clave Digital</label>
                                <div className="c-flexcenter">
                                    <Form.Control type="text" placeholder="Codigo SMS" name="sms" onChange={handleInputChange}/>
                                    <button className="c-button c-button--raised" onClick={() => generatePin()}>GENERAR CLAVE</button>
                                </div>
                            </div>
                        : ''
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button className="c-button c-button--default" onClick={props.onHide}>Cancelar</Button>
                    {
                        (type_tipo_firma  == 0)
                        ?
                            (TIPO_FIRMA == 1) 
                            ? <Button className="c-button c-button--raised c-secundary" onClick={() => signDocument("firmado")}>Firmar</Button>
                            : <Button className="c-button c-button--raised c-secundary" onClick={() => verifySMS()}>Firmar</Button>
                        :  <Button className="c-button c-button--raised c-secundary" onClick={() => signDocument("visado")}>Visar</Button>
                    }
                </Modal.Footer>
            </Modal>
        );
    }
    
    const MyModalObservation = (props) => {
    
        const [comment, setComment] = useState("");
        const [isFetching, setFetching] = useState(false);
        const handleInputChange = (e) => {
            setComment(e.target.value)
        }
    
        const observeDocument = () => {
            let role_user = "";
            if(comment == '' || comment == undefined){
                Swal.fire('','No puede dejar vac\u00CDo el campo de comentario','warning');
                return;
            }
            props.dataDocument.signers.filter(function(signers){
                const signers_rut = (signers.rut.length < 8 ) ? "0"+signers.rut : signers.rut;
                if(signers_rut == USER_RUT){
                    role_user = signers.role;
                }
            })
            setFetching(true);
            axios({
                method: 'POST',
                url: 'https://5cl.dec.com.pe/api/v1/sign/reject',
                data: {
                    user_rut: USER_RUT,
                    user_pin: USER_PIN,
                    user_role: role_user,
                    user_institution: INSTITUTION,
                    code: props.dataDocument.code,
                    comment: comment,
                    session_id: SESSION_ID
                },
                headers: {
                    'Content-Type': 'application/json',
                    'X-API-KEY' : VALUEAPIKEY
                }
            })
            .then(function (response) {
                setFetching(false);
                setModalShowObs(false);
                Swal.fire('','Documento rechazado con \u00E9xito','success');
                history.push('firmas');
            })
            .catch((error) => {
                setFetching(false);
                if (401 === error.response.status) {
                    Swal.fire({
                        icon: 'warning',
                        title: 'Sesi\u00F3n expirada',
                        text: 'Su sesi\u00F3n ha caducado. Ser\u00E1 redirigido a la p\u00E1gina de inicio de sesi\u00F3n para logearse nuevamente.',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        confirmButtonText: `OK`,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            logoutUser()
                        }
                    })
                }
            });
        }
    
        return (
            <Modal show={props.show} onHide={props.onHide} size="sm" backdrop="static" keyboard={false} aria-labelledby="contained-modal-title-vcenter" centered>
                { isFetching ? <Loading></Loading> : '' }
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Observar documento
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="c-input">
                        <Form.Control type="text" as="textarea" rows="3" placeholder="Comentario" name="comment" onChange={handleInputChange}/>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="c-button c-button--default" onClick={props.onHide}>Cancelar</Button>
                    <Button className="c-button c-button--raised c-secundary" onClick={() => observeDocument()}>Confirmar</Button>
                </Modal.Footer>
            </Modal>
        );
    }

    const setAlertNotSign = () =>{
        Swal.fire('','No esta habilitado para poder firmar este documento','warning');
    }

    const setAlertNotObserve = () =>{
        Swal.fire('','No esta habilitado para poder observar este documento','warning');
    }

    const changeFormatDate = (dateFormat) => {
        const date = dateFormat;
        const newDate = dateFormat.split(" ")[0];
        const newHour = dateFormat.split(" ")[1];
        var formatDate = newDate.split("/");
        var newDateFormat = formatDate[2]+'/'+formatDate[1] +'/'+formatDate[0] +' '+ newHour
        return newDateFormat;
    }

    const donwloadPdf = (urlPdf) => {
        setFetching(true)
        axios({
            url: urlPdf.file_preview,
            method: 'GET',
            responseType: 'blob',
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', urlPdf.name+'.pdf');
            document.body.appendChild(link);
            link.click();
            setFetching(false)
        });
    }

    return(
        <Layout fixedHeader fixedDrawer className="c-layout">
            <Header className="c-header">
                <div className="c-header__title">
                    <img src={LOGO_IDENTITY_HORIZONTAL} alt="Logo Identity" />
                </div>
            </Header>
            <Drawer className="c-drawer">
                <div className="c-drawer__image">
                    <div className="c-image">
                        <img src={INSTITUTION_IMG} alt="Logo Identity" />
                    </div>
                    <h2>{USERNAME}</h2>
                </div>
                <Navigation>
                    <Link to={{ pathname: 'empresas', state: { institutions: INSTITUTIONS }}} className="c-nav__item">
                        <div className="c-nav__icon">
                            <i className="material-icons">home</i>
                        </div>
                        <p>Mis empresas</p>
                    </Link>
                    <NavLink to="firmas" className="c-nav__item">
                        <div className="c-nav__icon">
                            <i className="material-icons">edit</i>
                        </div>
                        <p>Doc. por firmar</p>
                    </NavLink>
                    <NavLink to="proceso-firmas" className="c-nav__item">
                        <div className="c-nav__icon">
                            <i className="material-icons">rotate_left</i>
                        </div>
                        <p>Doc. en proceso de firmas</p>
                    </NavLink>
                    <NavLink to="firmados" className="c-nav__item">
                        <div className="c-nav__icon">
                            <i className="material-icons">done</i>
                        </div>
                        <p>Doc. firmados</p>
                    </NavLink>
                    <NavLink to="cambiar-contrasena" className="c-nav__item">
                        <div className="c-nav__icon">
                            <i className="material-icons">vpn_key</i>
                        </div>
                        <p>Cambio de contrase&ntilde;a</p>
                    </NavLink>
                    <a href="#" className="c-nav__item" onClick={() => logoutUser()}>
                        <div className="c-nav__icon">
                            <i className="material-icons">keyboard_return</i>
                        </div>
                        <p>Cerrar sesi&oacute;n</p>
                    </a>
                </Navigation>
            </Drawer>
            <Content>
                { isFetching ? <Loading></Loading> : null }
                <div className="c-page__title">
                    <p>Detalle del documento</p>
                </div>
                <div className="page-content">
                <div className="c-card--container">
                        <div className="c-card__detail">
                            <div className="c-detail__content">
                                <h2>{detailDocument.name}</h2>
                                <p>Estado: {
                                    (() => {
                                        switch( detailDocument.state ){
                                            case '0' : return 'Pendiente de Firma';
                                            case '1' : return 'En Proceso de Firma';
                                            case '2' : return 'Aprobado';
                                            case '3' : return 'Rechazado';
                                        }
                                    })()
                                }</p>
                                <p>C&oacute;digo: {detailDocument.code}</p>
                                <p>Fecha: {( detailDocument.date === '' || detailDocument.date === null || detailDocument.date === undefined ) ? '' : changeFormatDate(detailDocument.date)}</p>
                                <div className="c-detail__rutas">
                                    <h3>Ruta Vistos/Firmas</h3>
                                    {signerDocument.map((data,index)=>(
                                        <div key={index} className="c-rutas__item">
                                            <p>{
                                                (data.name == '') ? data.role : data.name
                                            }</p>
                                            <div className="c-rutas__item--state">
                                                <p>
                                                    {(() => {
                                                        switch( data.state ){
                                                            case 0 : return 'Pendiente';
                                                            case 1 : return 'Firmado';
                                                            case 2 : return 'Visado';
                                                            case 3 : return 'Rechazado';
                                                            case 4 : return 'Compartido';
                                                        }
                                                    })()}
                                                </p>
                                                <p>{( data.date === '' || data.date === null || data.date === undefined ) ? '' : changeFormatDate(data.date)}</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="c-detail__actions">
                                {(() => {
                                    switch( typeState ){
                                        case 0 : return(
                                            <div className="c-actions__button">
                                                <a href={detailDocument.file_preview} target="_blank" className="c-button c-button--raised">Ver documento</a>
                                                {
                                                    (detailDocument.can_sign === 1)
                                                    ?<Button className="c-button c-button--raised c-secundary" onClick={() => setModalShowSign(true)}>Firmar</Button>
                                                    :<Button className="c-button c-button--raised c-secundary" onClick={() => setAlertNotSign()}>Firmar</Button>
                                                }
                                                {
                                                    (detailDocument.can_sign === 1)
                                                    ?<Button className="c-button c-button--raised c-pastel" onClick={() => setModalShowObs(true)}>Observar</Button>
                                                    :<Button className="c-button c-button--raised c-pastel" onClick={() => setAlertNotObserve()}>Observar</Button>
                                                }
                                            </div>
                                        );
                                        case 1 : return(
                                            <div className="c-actions__button">
                                                <a href={detailDocument.file_preview} target="_blank" className="c-button c-button--raised">Ver documento</a>
                                                {/* <Button className="c-button c-button--raised" onClick={() => setModalShowDoc(true)}>Ver documento</Button> */}
                                            </div>
                                        );
                                        case 2 : return(
                                            <div className="c-actions__button">
                                                <a href={detailDocument.file_preview} target="_blank" className="c-button c-button--raised">Ver documento</a>
                                                <button onClick={() => donwloadPdf(detailDocument)} className="c-button c-button--raised c-secundary">Descargar</button>
                                            </div>
                                        );
                                    }
                                })()}
                                <div className="c-actions__code">
                                    <img src={'data:image/jpeg;base64,' + detailDocument.qr} alt="code qr" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <MyModalDocument show={modalShowDoc} onHide={() => setModalShowDoc(false)} srcIframe={detailDocument.file_preview} />
                <MyModalSign show={modalShowSign} onHide={() => setModalShowSign(false)} dataDocument={detailDocument}/>
                <MyModalObservation show={modalShowObs} onHide={() => setModalShowObs(false)} dataDocument={detailDocument}/>
            </Content>
        </Layout>
    )
}

export default DetailDocument;