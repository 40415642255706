import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import Loading from './Loading';
import { db } from '../firebase/firebase-config';
import Logo from '../images/logos/logo-identity.png';
import FONDO from '../images/fondo/fondo.png';
import { Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';

const Active = ({history}) => {

    const [isFetching, setFetching] = useState(false);
    const [dataUser, setDataUser] = useState({});
    const [datos, setDatos] = useState({
        newPassword : '',
        confirmPassword  : ''
    });
    
    const VALUEAPIKEY = 'ef2018c59ac4bb739be8698134a89ff2c1ad7608';

    useEffect(() => {
        setDataUser(history.location.state.user_data);
    },[])

    const handleInputChange = (e) => {
        setDatos({
            ...datos,
            [e.target.name] : e.target.value
        })
    }

    const styleBackground = {
        backgroundImage:  `url(${FONDO})`,
        backgroundSize: 'cover'
    }

    const changePasswordUser = () => {
        const newPassword = datos.newPassword;
        const confirmPassword = datos.confirmPassword;
        if(newPassword === '' || newPassword === undefined){
            Swal.fire('','No puede dejar vac\u00CDo el campo de contrase\u00F1a','warning');
            return;
        }
        if(confirmPassword === '' || confirmPassword === undefined){
            Swal.fire('','No puede dejar vac\u00CDo el campo de contrase\u00F1a','warning');
            return;
        }
        if(newPassword !== confirmPassword){
            Swal.fire('','Las contrase\u00F1as no coinciden','error');
            return;
        }
        setFetching(true);
        axios({
            method: 'POST',
            url: 'https://5cl.dec.com.pe/api/v1/auth/recover_password',
            data: {
                user_rut: dataUser.user_rut,
                serial_number: confirmPassword
            },
            headers: {
                'Content-Type': 'application/json',
                'X-API-KEY' : VALUEAPIKEY
            }
        })
        .then(function (response) {
            createPin(response.data.result.token)
        })
        .catch((error) => {

        });
    }

    const createPin = (token) => {
        axios({
            method: 'POST',
            url: 'https://5cl.dec.com.pe/api/v1/auth/create_pin',
            data: {
                user_rut: dataUser.user_rut,
                serial_number: dataUser.user_rut,
                token: token,
                pin: datos.confirmPassword
            },
            headers: {
                'Content-Type': 'application/json',
                'X-API-KEY' : VALUEAPIKEY
            }
        })
        .then(function (response) {
            setFetching(false);
            setDatos({
                newPassword : '',
                confirmPassword  : ''
            })
            db.collection("usuarios").where("user_rut", "==", dataUser.user_rut).get()
            .then(function(querySnapshot) {
                querySnapshot.forEach(function(snapSession) {
                    snapSession.ref.update({ primera_session : false })
                })
            })
            Swal.fire('','Su cuenta ha sido activada','success');
            history.push('/');
        })
        .catch((error) => {
            setFetching(false);
        });
    }

    return(
        <div className="c-login" style={styleBackground}>
            { isFetching ? <Loading></Loading> : '' }
            <div className="c-login__card">
                <div className="c-card__title">
                <img src={Logo} alt="Logo Identity" />
                <p>Activacion de cuenta</p>
                </div>
                <div className="c-card__body">
                    <div className="c-input">
                        <label className="c-label">Nombre de usuario</label>
                        <Form.Control type="text" placeholder="Nombre de usuario" name="username" value={ dataUser.user_name || ""} disabled />
                    </div>
                    <div className="c-input">
                        <Form.Control type="password" placeholder="Nueva contraseña" name="newPassword" onChange={handleInputChange}/>
                    </div>
                    <div className="c-input">
                        <Form.Control type="password" placeholder="Confirmar nueva contraseña" name="confirmPassword" onChange={handleInputChange}/>
                    </div>
                </div>
                <div className="c-card__actions">
                    <button className="c-button c-button--raised c-button--width" onClick={() => changePasswordUser()}>Activar cuenta</button>
                    <Link to="/" className="c-forget--password">Cancelar</Link>
                </div>
                <div className="c-card__footer">
                    <p>Todos los derechos reservados por GRUPO CORIL.</p>
                </div>
            </div>
        </div>
    )
}

export default Active;