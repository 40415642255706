import React, { useEffect , useState } from 'react';
import Swal from 'sweetalert2';
import Loading from './Loading';
import { db } from '../firebase/firebase-config';
import Logo from '../images/logos/logo-identity.png';
import FONDO from '../images/fondo/fondo.png';
import { Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';

const RestorePassword = ({history}) => {

    const [isFetching, setFetching] = useState(false);
    const [typeVerify, setTypeVerify] = useState("");
    const [dataPinId, setDataPinId] = useState("");
    const [dataUser, setDataUser] = useState({});
    const [datos, setDatos] = useState({
        newPassword : '',
        confirmPassword  : '',
        sms: ''
    });

    const VALUEAPIKEY = 'ef2018c59ac4bb739be8698134a89ff2c1ad7608';

    useEffect(()=> {
        setDataUser(history.location.state.dataUser);
        setTypeVerify(history.location.state.typeVerify);
        setDataPinId(history.location.state.pinId);
    }, [])

    const handleInputChange = (e) => {
        setDatos({
            ...datos,
            [e.target.name] : e.target.value
        })
    }
    const styleBackground = {
        backgroundImage:  `url(${FONDO})`,
        backgroundSize: 'cover'
    }

    const validatePassword = (password) => {
        const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
        return re.test(password);
    }

    const changePasswordUser = () => {
        setFetching(true);
        axios({
            method: 'POST',
            url: 'https://5cl.dec.com.pe/api/v1/auth/recover_password',
            data: {
                user_rut: dataUser.user_rut,
                serial_number: datos.confirmPassword
            },
            headers: {
                'Content-Type': 'application/json',
                'X-API-KEY' : VALUEAPIKEY
            }
        })
        .then(function (response) {
            createPin(response.data.result.token)
        })
        .catch((error) => {
            
        });
    }

    const createPin = (token) => {
        axios({
            method: 'POST',
            url: 'https://5cl.dec.com.pe/api/v1/auth/create_pin',
            data: {
                user_rut: dataUser.user_rut,
                serial_number: dataUser.serial_number,
                token: token,
                pin: datos.confirmPassword
            },
            headers: {
                'Content-Type': 'application/json',
                'X-API-KEY' : VALUEAPIKEY
            }
        })
        .then(function (response) {
            setFetching(false);
            setDatos({
                newPassword : '',
                confirmPassword  : '',
                sms: ''
            })
            Swal.fire('','Su contrase\u00F1a fue cambiada con \u00E9xito','success');
            history.push('/');
        })
        .catch((error) => {
            setFetching(false);
        });
    }

    const verifyPinId = () => {
        if(typeVerify === 'email'){
            verifyEmail();
        }else{
            verifySMS();
        }
    }

    const verifyEmail = () => {
        const newPassword = datos.newPassword;
        const confirmPassword = datos.confirmPassword;
        const sms = datos.sms;
        if(newPassword === '' || newPassword === undefined || confirmPassword === '' || confirmPassword === undefined){
            Swal.fire('','No puede dejar vac\u00CDo el campo de contrase\u00F1a','warning');
            return;
        }
        if(newPassword.length < 8 || confirmPassword.length < 8){
            Swal.fire('','Debe tener mas de 8 caracteres el campo de contrase\u00F1a','warning');
            return;
        }
        if(!validatePassword(newPassword) || !validatePassword(confirmPassword)){
            Swal.fire('','No cumple con el formato de contrase\u00F1a','warning');
            return;
        }
        if(newPassword !== confirmPassword){
            Swal.fire('','Las contrase\u00F1as no coinciden','error');
            return;
        }
        if(sms == '' || sms == undefined){
            Swal.fire('','No puede dejar vac\u00CDo el campo de SMS','warning');
            return;
        }
        setFetching(true);
        db.collection("usuarios").where("user_clavedigital", "==", sms).get()
        .then(function(querySnapshot) {
            if(querySnapshot.empty){
                setFetching(false);
                Swal.fire('','Clave digital inv\u00E1lida','error');
            }else{
                setFetching(false);
                changePasswordUser();
            }
        });
    }

    const verifySMS = () => {
        const newPassword = datos.newPassword;
        const confirmPassword = datos.confirmPassword;
        const sms = datos.sms;
        if(newPassword === '' || newPassword === undefined || confirmPassword === '' || confirmPassword === undefined){
            Swal.fire('','No puede dejar vac\u00CDo el campo de contrase\u00F1a','warning');
            return;
        }
        if(newPassword.length < 8 || confirmPassword.length < 8){
            Swal.fire('','Debe tener mas de 8 caracteres el campo de contrase\u00F1a','warning');
            return;
        }
        if(!validatePassword(newPassword) || !validatePassword(confirmPassword)){
            Swal.fire('','No cumple con el formato de contrase\u00F1a','warning');
            return;
        }
        if(newPassword !== confirmPassword){
            Swal.fire('','Las contrase\u00F1as no coinciden','error');
            return;
        }
        if(sms == '' || sms == undefined){
            Swal.fire('','No puede dejar vac\u00CDo el campo de SMS','warning');
            return;
        }
        setFetching(true);
        axios({
            method: 'POST',
            url: 'https://9npyr.api.infobip.com/2fa/2/pin/'+dataPinId+'/verify',
            data: {
                pin: sms
            },
            headers: {
                'Authorization': 'App 8c92d1ac9b27c3f3cb0089d81e5bea3d-24643b6d-6f84-4971-afdd-ea8f9de62772',
                'Content-Type':  'application/json',
                'Access-Control-Allow-Credentials' : 'true',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'GET, POST, PATCH, DELETE, PUT, OPTIONS',
                'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With'
            }
        })
        .then(function (response) {
            setFetching(false);
            if(response.data.verified == true){
                changePasswordUser();
            }else{
                Swal.fire('','Clave digital inv\u00E1lida','error');
            }
        })
        .catch((error) => {
            setFetching(false);
        });
    }

    return (
        <div className="c-login" style={styleBackground}>
            { isFetching ? <Loading></Loading> : '' }
            <div className="c-login__card">
                <div className="c-card__title">
                <img src={Logo} alt="Logo Identity" />
                <p>Cambio de contrase&ntilde;a</p>
                </div>
                <div className="c-card__body">
                    <div className="c-input">
                        <label className="c-label">Nombre de usuario</label>
                        <Form.Control type="text" placeholder="Nombre de usuario" name="username" value={dataUser.user_name + ' ' + dataUser.user_apellido_paterno + ' ' + dataUser.user_apellido_materno || ""} disabled />
                    </div>
                    <div className="c-formato">
                        <p>Por favor, tener en cuenta lo siguiente para su nueva contrase&ntilde;a:</p>
                        <ul>
                            <li>Debe contener, como m&iacute;nimo, ocho caracteres.</li>
                            <li>Debe contener al menos, una letra may&uacute;scula, una letra min&uacute;scula, un n&uacute;mero y un s&iacute;mbolo</li>
                        </ul>
                    </div>
                    <div className="c-input">
                        <Form.Control type="password" placeholder="Contraseña" name="newPassword" onChange={handleInputChange}/>
                    </div>
                    <div className="c-input">
                        <Form.Control type="password" placeholder="Confirmar contraseña" name="confirmPassword" onChange={handleInputChange}/>
                    </div>
                    <div className="c-input">
                        <Form.Control type="text" placeholder="Clave digital" name="sms" onChange={handleInputChange}/>
                    </div>
                </div>
                <div className="c-card__actions">
                    <button className="c-button c-button--raised c-button--width" onClick={() => verifyPinId()}>Cambiar contrase&ntilde;a</button>
                    <Link to="generar-contrasena" className="c-forget--password">Regresar</Link>
                </div>
                <div className="c-card__footer">
                    <p>Todos los derechos reservados por GRUPO CORIL.</p>
                </div>
            </div>
        </div>
    )

}

export default RestorePassword;