import React from 'react';
import Login from './components/Login';
import GeneratePassword from './components/GeneratePassword';
import RestorePassword from './components/RestorePassword';
import Active from './components/Active';
import Company from './components/Company';
import DetailDocument from './components/DetailDocument';
import Sign from './components/Sign';
import Signed from './components/Signed';
import Process from './components/Process';
import ChangePassword from './components/ChangePassword';
import { BrowserRouter, Route } from 'react-router-dom';

const App = () => {

    return (
        <BrowserRouter>
            <Route path="/" exact component={Login} />
            <Route path="/generar-contrasena" component={GeneratePassword} />
            <Route path="/restaurar-contrasena" component={RestorePassword} />
            <Route path="/activar-cuenta" component={Active} />
            <Route path="/empresas" component={Company} />
            <Route path="/firmas" component={Sign} />
            <Route path="/firmados" component={Signed} />
            <Route path="/proceso-firmas" component={Process} />
            <Route path="/detalle-documento" component={DetailDocument} />
            <Route path="/cambiar-contrasena" component={ChangePassword} />
        </BrowserRouter>
    )
}

export default App;
