import React, { useState } from 'react';
import Loading from './Loading';
import LOGO_IDENTITY from '../images/logos/logo-identity.png';
import FONDO from '../images/fondo/fondo.png';
import { Link } from 'react-router-dom';
import axios from 'axios';

const Company = ({history}) => {

    const [isFetching, setFetching] = useState(false);

    const styleBackground = {
        backgroundImage:  `url(${FONDO})`,
        backgroundSize: 'cover'
    }

    const loginCompany = (dataInstitution) =>{
        const username = dataInstitution.user_name;
        const userpin = dataInstitution.user_pin;
        const VALUEAPIKEY = dataInstitution["X-API-KEY"];
        setFetching(true);
        axios({
            method: 'POST',
            url: 'https://5cl.dec.com.pe/api/v1/auth/login',
            data: {
                user_name: username,
                user_pin: userpin
            },
            headers: {
                'Content-Type': 'application/json',
                'X-API-KEY' : VALUEAPIKEY
            }
        })
        .then(function (response) {
            localStorage.setItem('VALUEAPIKEY_COMPANY', VALUEAPIKEY);
            localStorage.setItem('SESSION_SUPER_ID', response.data.session_id);
            localStorage.setItem('INSTITUTION', dataInstitution.institution);
            localStorage.setItem('INSTITUTION_IMG', dataInstitution.img);
            setFetching(false);
            history.push('/firmas');
        })
        .catch((error) => {
            setFetching(false);
        });
    }

    return(
        <div className="c-login" style={styleBackground}>
            { isFetching ? <Loading></Loading> : '' }
            <div className="c-login__card">
                <div className="c-card__title">
                    <img src={LOGO_IDENTITY} alt="Logo Identity" />
                    <p>Seleccione la empresa</p>
                </div>
                <div className="c-card__body">
                    {history.location.state.institutions.map(data =>(
                        <div key={data.user_name} className="c-logo__image" onClick={() => loginCompany(data)}>
                            <img src={data.img} alt={data.user_name} />
                        </div>
                    ))}
                </div>
                <div className="c-card__actions">
                    <Link to="/" className="c-button c-button--raised c-button--width">Regresar</Link>
                </div>
                <div className="c-card__footer">
                    <p>Todos los derechos reservados por GRUPO CORIL.</p>
                </div>
            </div>
        </div>
    )
}

export default Company;