import React, { useState } from 'react';
import Swal from 'sweetalert2';
import Loading from './Loading';
import { db } from '../firebase/firebase-config';
import LOGO from '../images/logos/logo-identity.png';
import FONDO from '../images/fondo/fondo.png';
import { RadioGroup , Radio } from 'react-mdl';
import { Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';

const GeneratePassword = ({ history }) => {

    const [isFetching, setFetching] = useState(false);
    const [isHide, setHide] = useState(false);
    const [dataUser, setDataUser] = useState({});
    const [document, setDocument] = useState("");
    const [dataPhone, setDataPhone] = useState("");
    const [dataEmail, setDataEmail] = useState("");
    const [radioButton, setRadioButton] = useState('email');

    const styleBackground = {
        backgroundImage:  `url(${FONDO})`,
        backgroundSize: 'cover'
    }

    const handleInputChange = (e) => {
        setDocument(e.target.value)
    }
    const handleRadioButtonChange = (e) => {
        setRadioButton(e.target.value)
    }

    const generatePinByEmail = () => {
        setFetching(true);
        axios({
            method: 'POST',
            url: 'https://apps.grupocoril.com/identity-spring-boot-prod/claveUsuario',
            data: {
                user_tipoDocumento: dataUser.user_tipoDocumento,
                user_rut: dataUser.user_rut
            },
            headers: {
                'Content-Type':  'application/json',
                'Access-Control-Allow-Credentials' : 'true',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'GET, POST, PATCH, DELETE, PUT, OPTIONS',
                'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With'
            }
        })
        .then(function (response) {
            setFetching(false);
            console.log(response.data)
            history.push({
                pathname: '/restaurar-contrasena',
                state: {
                    dataUser: dataUser,
                    typeVerify : 'email'
                }
            });
        })
        .catch((error) => {
            console.log(error);
            setFetching(false);
        });
    }

    const generatePinByPhone = () => {
        setFetching(true);
        axios({
            method: 'POST',
            url: 'https://9npyr.api.infobip.com/2fa/2/pin?ncNeeded=true',
            data: {
                applicationId: "342552A181526F34C8F61F36DF4920B9",
                messageId: "E9235174395D240B68DF603E1C7745AC",
                from : "Coril_Identity",
                to: "+"+dataUser.user_phone,
                "placeholders":{}
            },
            headers: {
                'Authorization': 'App 8c92d1ac9b27c3f3cb0089d81e5bea3d-24643b6d-6f84-4971-afdd-ea8f9de62772',
                'Content-Type':  'application/json',
                'Access-Control-Allow-Credentials' : 'true',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'GET, POST, PATCH, DELETE, PUT, OPTIONS',
                'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With'
            }
        })
        .then(function (response) {
            setFetching(false);
            console.log(response.data)
            history.push({
                pathname: '/restaurar-contrasena',
                state: {
                    dataUser: dataUser,
                    typeVerify : 'phone',
                    pinId : response.data.pinId
                }
            });
        })
        .catch((error) => {
            console.log(error);
            setFetching(false);
        });
    }

    const validarUserRut = () =>{
        if(document === '' || document === undefined){
            alert('Ingrese documento')
            return;
        }
        setFetching(true);
        console.log(document);
        db.collection("usuarios").where("user_rut", "==", document).get()
        .then(function(querySnapshot) {
            if(querySnapshot.empty){
                setFetching(false);
                setHide(false);
                Swal.fire('','DNI no encontrado','error');
            }else{
                querySnapshot.forEach(function(dataUser) {
                    setFetching(false);
                    setHide(true);
                    setDataPhone(dataUser.data().user_phone);
                    setDataEmail(dataUser.data().user_email)
                    setDataUser(dataUser.data());
                    console.log(dataUser.data());
                });
            }
        });
    }

    const regexPhone = (str) => {
        return str.replace(/(?<=.).(?=[^@]*?.{2})/g, "*");
    }
    const regexEmail = (str) => {
        return str.replace(/(?<=.).(?=[^@]*?.@)/g, "*");
    }

    const confirmCode = () =>{
        if(radioButton === 'email'){
            generatePinByEmail();
            console.log("mandar email");
        }else{
            generatePinByPhone();
            console.log("mandar phone");
        }
    }

    return (
        <div className="c-login" style={styleBackground}>
            { isFetching ? <Loading></Loading> : '' }
            <div className="c-login__card">
                <div className="c-card__title">
                    <img src={LOGO} alt="Logo Identity" />
                    <p><strong>Generaci&oacute;n de nueva contrase&ntilde;a</strong></p>
                </div>
                <div className="c-card__body">
                    <div className="c-input">
                        <label className="c-label">Validar DNI</label>
                        <div className="c-flexcenter">
                            <Form.Control type="text" placeholder="Numero de documento" name="document" onChange={handleInputChange}/>
                            <button className="c-button c-button--raised" onClick={() => validarUserRut()}>VALIDAR</button>
                        </div>
                    </div>
                    {
                        isHide
                        ? <div className="c-digital">
                            <p>Enviar Clave Digital a:</p>
                            <RadioGroup container="ul" childContainer="li" name="restaurar" value={radioButton} onChange={handleRadioButtonChange}>
                                <Radio name="email" value="email">{regexEmail(dataEmail)}</Radio>
                                <Radio name="phone" value="phone">{regexPhone(dataPhone)}</Radio>
                            </RadioGroup>
                        </div>
                        : null
                    }
                    <div className="c-card__actions flex">
                        <Link to="/" className="c-cancel">Cancelar</Link>
                        { isHide ? <button className="c-button c-button--raised" onClick={() => confirmCode()}>Confirmar</button> : null}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GeneratePassword;